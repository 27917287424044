<template>
  <TosListVuetify entityName="files" :headers="headers" :excludeButtons="excludeButtons" :defaultFilters="defaultFilters" v-bind:filterOnMounted="false">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field label="File name" v-model="slotProps.data.fileName" />
          </v-col>
          <v-col cols="4">
            <v-text-field label="Codice" v-model="slotProps.data.objectCode" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #item.actions="slotProps">
      <div class="text-center">
      <v-btn icon title="Download file" @click.prevent="downLoadFile(slotProps.data)">
        <v-icon medium>mdi-download</v-icon>
      </v-btn>
    </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "FilesList",
  props: {
    codice: { type: String },
  },
  data() {
    return {
      defaultFilters: {},
      headers: [
        { text: "Nome file", value: "fileName" },
        { text: "Tipo file", value: "fileType" },
        { text: "Codice oggetto", value: "objectCode" },
        { text: "Esito", value: "esito" },
        { text: "Data", value: "createdAt", formatValue: "formatDateTimeFromDate" },
      ],
      excludeButtons: {
        add: true,
      },
    };
  },
  service: null,
  created() {},
  mounted() {
    this.defaultFilters = { objectCode: this.codice };
  },
  methods: {
    async downLoadFile(data) {
      const _callConfig = {
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "*/*",
          Pragma: "no-cache",
        },
      };
      const response = await this.$api.get(this.$apiConfiguration.BASE_PATH + "files/download/" + data.id, _callConfig);
      const fileURL = window.URL.createObjectURL(new Blob([response], {}));
      const fileLink = document.createElement("a");
      fileLink.setAttribute("href", fileURL);
      fileLink.setAttribute("download", data.fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
  components: {
    TosListVuetify,
  },
  mixins: [FormatsMixins],
};
</script>
